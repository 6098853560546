<template>
  <div class="progress-box">
    <span class="label">{{ label }}</span>
    <div class="progress-bar">
      <div class="progress-value" v-bind:style="getValueStyle"></div>
    </div>
    <div class="description">
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.label {
  display: block;
  margin-bottom: 0.25rem;
}

.progress-bar {
  width: 100%;
  height: 1rem;
  background-color: lighten($dark-color, 2%);
}

.progress-value {
  height: 100%;
  background-color: $pop-color;
  transition: background-color 0.5s ease-in-out;
}

.progress-bar,
.progress-value {
  border-radius: 0.5rem;
}

.progress-box {
  position: relative;
  margin-bottom: 1rem;

  .description {
    display: none;
    position: absolute;
    top: 2.75rem;
    background-color: $text-color;
    color: $dark-color;
    border-radius: 0.25rem;
    padding: 0.5rem;
    box-shadow: $my-box-shadow;
    z-index: 97;
    animation: fade-in 0.5s ease-in-out 0s 1;

    @keyframes fade-in {
      from {
        opacity: 0;
        transform: translateY(-0.5rem);
      }
      to {
        opacity: 1;
        transform: none;
      }
    }

    p {
      user-select: none;
      margin: 0;
    }
  }
}

.progress-box:hover {
  .progress-value {
    background-color: $text-color;
  }

  .description {
    display: block;
  }
}
</style>

<script lang="ts">
import { defineComponent, StyleValue } from "vue";

export default defineComponent({
  props: ["label", "value", "description"],
  computed: {
    getValueStyle(): StyleValue {
      return {
        width: this.value + "%",
      };
    },
  },
});
</script>