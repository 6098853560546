<template>
  <div class="about-me-container">
    <section class="column-item card about-me">
      <div class="card-header">
        <h2>
          <font-awesome-icon
            :icon="['fas', 'handshake']"
            class="title-icon"
          />Bemutatkozás
        </h2>
      </div>
      <div class="card-body">
        <p>
          Makai Marcell vagyok, (frontend-heavy) full-stack szoftverfejlesztő.
          2020 áprilisában, briliánsan időzítve, a pandémia kirobbanása és a
          karantén elrendelése után nem sokkal végeztem az egyik ismertebb
          bootcamp Java Full-Stack Fejlesztő képzésén. A frontend technológiák
          már a képzés előtt sem voltak ismeretlenek számomra, a sitebuild
          kisebb-nagyobb lendülettel, de a szerves része volt addig is az
          életemnek. A képzés elvégzése után egy magyar cégcsoport budapesti
          irodájának alkalmazásában kezdtem el dolgozni, mint full-stack
          fejlesztő és teszem ezt mind a mai napig.
        </p>
        <p>Same in English.</p>
      </div>
    </section>
    <section class="column-item photo-box photo">
      <img src="@/assets/img/duplaem.jpg" alt="Duplaem" />
    </section>
    <section class="column-item card hobbies">
      <div class="card-header">
        <h3>
          <font-awesome-icon
            :icon="['fas', 'person-hiking']"
            class="title-icon"
          />Hobbik
        </h3>
      </div>
      <div class="card-body">
        <p>
          Edzőtermi edzés, futás, kirándulás, de nyitott vagyok a legtöbb
          mozgásformára. Szeretek fotózni, videózni, koncertekre járni, valamint
          utazni és a csapatépítőkön utolsó emberként elhagyni a helyszínt.
        </p>
      </div>
    </section>
    <section class="column-item card contact">
      <div class="card-header">
        <h3>
          <font-awesome-icon
            :icon="['fas', 'envelope']"
            class="title-icon"
          />Kapcsolat
        </h3>
      </div>
      <div class="card-body">
        <div class="icon-box">
          <a
            href="https://www.linkedin.com/in/makaimarcell"
            target="_blank"
            class="icon-link"
          >
            <font-awesome-icon :icon="['fab', 'linkedin']" size="2xl" />
          </a>
          <a
            href="https://github.com/makaimarcell"
            target="_blank"
            class="icon-link"
          >
            <font-awesome-icon :icon="['fab', 'github']" size="2xl" />
          </a>
        </div>
        <p>
          (GitHubon jelenleg gyakorlatilag minden projekt privát, de hátha
          egyszer másképp lesz.)
        </p>
      </div>
    </section>
    <section class="column-item card skills">
      <div class="card-header">
        <h3>
          <font-awesome-icon
            :icon="['fas', 'screwdriver-wrench']"
            class="title-icon"
          />Skillek
        </h3>
      </div>
      <div class="card-body">
        <p>(Részletekért vidd a kurzort az aktuális skillre.)</p>
        <progress-bar-component
          label="HTML5"
          value="90"
          description="Pár HTML-tag még lehet, hogy tud meglepetést okozni, de alapvetően erős a tudásom."
        ></progress-bar-component>
        <progress-bar-component
          label="CSS3"
          value="80"
          description="Erős, magabiztos tudás, hatalmas érdeklődéssel párosulva."
        ></progress-bar-component>
        <progress-bar-component
          label="Tailwind CSS"
          value="90"
          description="Privát projekteknél előszeretettel használom, munkám során csak pilot feladat keretein belül használtam."
        ></progress-bar-component>
        <progress-bar-component
          label="Bootstrap"
          value="90"
          description="Munkám során folyamatosan használom."
        ></progress-bar-component>
        <progress-bar-component
          label="JavaScript"
          value="65"
          description="Magabiztos tudás."
        ></progress-bar-component>
        <progress-bar-component
          label="TypeScript"
          value="65"
          description="Magabiztos tudás."
        ></progress-bar-component>
        <progress-bar-component
          label="Angular"
          value="65"
          description="Hobbi projekteken és munkám során egyaránt használom."
        ></progress-bar-component>
        <progress-bar-component
          label="Vue.js"
          value="50"
          description="Munkám során csak pilot feladatnál használtam eddig. Kisebb privát projekteknél folyamatosan fejlesztek benne."
        ></progress-bar-component>
        <progress-bar-component
          label="Java"
          value="60"
          description="Munkám során rendszeresen használom."
        ></progress-bar-component>
        <progress-bar-component
          label="Spring Boot"
          value="30"
          description="Tanulmányaim alatt, hobbi projektnél és munkám során is használtam."
        ></progress-bar-component>
        <progress-bar-component
          label="SQL"
          value="40"
          description="Munkám során használom."
        ></progress-bar-component>
        <p>Egyéb: SCSS, HQL, Git, Jira</p>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.column-item {
  width: 100%;
}

.photo-box {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: $my-box-shadow-smaller;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media screen and (orientation: portrait), (max-width: 768px) {
  .about-me-container {
    display: grid;
    row-gap: 2.5rem;
  }

  .photo {
    order: 1;
  }

  .about-me {
    order: 2;
  }

  .skills {
    order: 3;
  }

  .hobbies {
    order: 4;
  }

  .contact {
    order: 5;
  }
}

@media screen and (orientation: landscape) and (min-width: 768px) {
  .about-me-container {
    height: auto;
    column-count: 2;
    column-gap: 1.25rem;
    break-inside: avoid-column;
  }

  .column-item {
    margin-bottom: 1.25rem;
    break-inside: avoid-column;
  }

  .about-me {
    column-span: all;
  }

  .photo-box {
    img {
      transform: none;
      transition: transform 0.25s linear;
    }

    &:hover img {
      transform: scale(1.1) rotate(1deg);
    }
  }
}
</style>